import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import BancosCreate from "./BancosCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";

function ListadoBancos() {
  const { user } = useContext(AuthContext);
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const BANCO_DEVOLUCION = process.env.REACT_APP_BANCO_DEVOLUCION;
  const BANCO_BONIFICACION = process.env.REACT_APP_BANCO_BONIFICACION;

  const [bancos, setBancos] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [banco, setBanco] = useState("");
  const [cuenta, setCuenta] = useState("");

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editCuenta(event) {
    event.preventDefault();
    const URL_BANCOS_EDIT = `${URL_BANCOS}/${idEdit}`;
    axios
      .patch(
        URL_BANCOS_EDIT,
        {
          banco,
          cuenta
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Cuenta',
            detalle: `${banco} / ${cuenta}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, banco, cuenta) {
        setBanco(banco);
        setCuenta(cuenta)
        setIdEdit(idEdit);
        toggle();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.bancos ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Cuentas</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Banco</th>
                          <th className="tituloTabla">Cuenta</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bancos
                          .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.banco}</td>
                                  <td>{a.cuenta}</td>
                                  <td>
                                    {a._id != BANCO_DEVOLUCION && a._id != BANCO_BONIFICACION?(
                                        <div>
                                        <Button
                                          color="info"
                                          id="Editar"
                                          size="sm"
                                          onClick={(e) => jalaInfo(a._id, a.banco, a.cuenta)}
                                        >
                                          <i class="far fa-edit"></i>{" "}
                                        </Button>

                                        <Baja
                                          idStatus={a._id}
                                          is_active={a.is_active}
                                          URL_BAJA={
                                            process.env.REACT_APP_URL_BANCOS
                                          }
                                        />
                                        </div>
                                    ):undefined}
                                       
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <BancosCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Cuenta</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Banco</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={banco}
            required
            onChange={(e) => {
              setBanco(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Cuenta</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={cuenta}
            required
            onChange={(e) => {
              setCuenta(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCuenta}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoBancos;
