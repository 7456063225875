import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function PuestosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PUESTOS = process.env.REACT_APP_URL_PUESTOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [text, setText] = useState(false);

  const [name, setName] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(process.env.REACT_APP_AREAS_GENERAL);

  useEffect(() => {
   
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
        axios
          .get(URL_COLABORADORES, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allColaboradores = res.data;
            setColaboradores(allColaboradores);
          })
          .catch((err) => {
            console.log(err);
          });
  },[]);

  const savePuestos = (event) => {
    event.preventDefault();
    axios
      .post(
        URL_PUESTOS,
        {
          name,
          colaboradores: selectedColaborador,
          areas: selectedArea,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Puesto Admin',
            detalle: `Nombre: ${name}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };
  return (
    <>
      <div className="container">
        <h3 align="center">Crea un Puesto nuevo</h3>
        <Form onSubmit={savePuestos}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Puesto</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Nombre del Puesto"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Label className="mr-sm-2">Jefe</Label>
            <Input
                className="col-sm-12"
                type="select"
                value={selectedColaborador}
                required
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                }}
              >
                <option value="0">Selecciona un Jefe</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                  return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
             
            {user.areas == AREAS_GENERAL ? (
                <div>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona una Area</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                          return <option value={a._id}>{a.name}</option>
                      })}
                  </Input>
                </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona una Area</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </div>
              )} 
          </FormGroup>
          <br />
          <Row>
          {user.puestos_create  ?(
            <Button type="submit" className="btn btn-success">
              Crear Puesto
            </Button>
            ):(
              <Button type="submit" className="btn btn-success" disabled>
              Crear Puesto
            </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                  window.location.href = "/admin/ListadoNominas";
                }}
              />
            </div>
            <Button
              href="/MenuNominas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default PuestosCreate;
