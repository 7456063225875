import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function DestinosCreate() {
  const { user } = useContext(AuthContext);
  const URL_DESTINOS = process.env.REACT_APP_URL_DESTINOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);


  const saveDestinos = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_DESTINOS,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Destino",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea un nuevo Destino</h3>
        <Form onSubmit={saveDestinos}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Destino
            </Label>
            <Input
              type="text"
              placeholder="Nombre"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormGroup>
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuInventarios">
              Regresar
            </Button>
            </Row>
        </Form>
      </div>
    </>
  );
}

export default DestinosCreate;
