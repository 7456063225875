import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";

import Header from "../../layout/Header/Header";

function BotonesArticulos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_articulos ?(
      <div className="container">
        <br />
        <br />
          <Row>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Lineas" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Lineas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Lineas
          </Button> }
            </Col>
            
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Marcas" className="botonesMenu" color="success">
              <i class="far fa-copyright fa-7x"></i>
              <br />
              <br />
              Marcas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="far fa-copyright fa-7x"></i>
            <br />
            <br />
            Marcas
          </Button> }
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Familias" className="botonesMenu" color="success">
              <i class="fas fa-users fa-7x"></i>
              <br />
              <br />
              Familias
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-users fa-7x"></i>
            <br />
            <br />
            Familias
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Colecciones" className="botonesMenu" color="success">
              <i class="fas fa-calendar-alt fa-7x"></i>
              <br />
              <br />
              Colecciones
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-calendar-alt fa-7x"></i>
            <br />
            <br />
            Colecciones
          </Button> }
            </Col>
            
          </Row>
          <br />
          <Row>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulos" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Modelos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Modelos
          </Button> }
          </Col>
          </Row>
         
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesArticulos;
