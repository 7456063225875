import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoDinerosTiendas() {
  const { user } = useContext(AuthContext);
  const URL_DINEROS_TIENDAS = process.env.REACT_APP_URL_DINEROS_TIENDAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    if(user.areas){
    if (user.areas != AREAS_GENERAL) {
      axios
        .get(
          `${URL_DINEROS_TIENDAS}DinerosVentasDiario/${user.areas}/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idDineroTiendas > b.idDineroTiendas ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idDineroTiendas,
                fecha: a.fecha,
                observaciones: a.observaciones,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                cortesia: a.cortesia,
                area:a.areas[0].name,
                is_active: a.is_active
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${URL_DINEROS_TIENDAS}DinerosVentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idDineroTiendas > b.idDineroTiendas ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idDineroTiendas,
                fecha: a.fecha,
                observaciones: a.observaciones,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                cortesia: a.cortesia,
                area:a.areas[0].name,
                is_active: a.is_active
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  }, [user, selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) 
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Entradas de Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          // "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
    });
    doc.save(`DinerosTiendas.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) 
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Efectivo: a.efectivo,
          TDC: a.tdc,
          Transfer: a.transfer,
          Cortesia: a.cortesia,
          Total: a.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDinerosTiendas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDinerosTiendas",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) 
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Entradas de Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          // "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
    });
    doc.save(`DinerosTiendas.pdf`);
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ventas",
          email: mailTo,
          fileName: "ListadoVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Total", field: "total", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.total.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }


    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total" &&
      sorting.field != "numero" &&
      sorting.field != "total"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total" ||
        sorting.field == "numero" ||
        sorting.field == "total" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total" ||
        sorting.field == "numero"  ||
        sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.abonosTiendas ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/DinerosTiendasCreate"
                >
                  Nueva Entrada
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Entrada
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Entradas Dinero</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Entradas de Dinero</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
            
            </tr>
            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}
                      </td>
                     
                    </tr>
                  );
               
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ListadoDinerosTiendas;
