import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoDisponiblePantacos() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const AREAS_PANTACO = process.env.REACT_APP_AREAS_PANTACO;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [articulosPedidos, setArticulosPedidos] = useState([]);
  const [modalPedidos, setModalPedidos] = useState(false);
  const togglePedidos = () => setModalPedidos(!modalPedidos);

  const [totalTotal, setTotalTotal] = useState(0);
  const [totalPR, setTotalPR] = useState(0);
  const [totalPed, setTotalPed] = useState(0);
  const [totalTR, setTotalTR] = useState(0);
  const [totalDis, setTotalDis] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [anos, setAnos] = useState([]);
  const [selectedAno, setSelectedAno] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");

  const [fechasEstimada, setFechasEstimada] = useState([]);
  const [codigoFecha, setCodigoFecha] = useState("");
  const [nombreFecha, setNombreFecha] = useState("");
  const [modalFechasEstimada, setModalFechasEstimada] = useState(false);
  const toggleFechasEstimada = () =>
    setModalFechasEstimada(!modalFechasEstimada);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const [idEditArticulo, setIdEditArticulo] = useState("");
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_INVENTARIOS}DisponibleAlmacenPANTACOS`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        let array = allDisponible.map((a)=>{
          if(a.inventario != 0 || a.porRecibir != 0 || a.pedido !=0 || a.disponible !=0){
          return a
          }
        }).filter(function (el) {
          return el != null;
        })
        setComments(array);

        let unicosAno = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.ano === value.ano)
        );
        setAnos(unicosAno);

        let unicosCol = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idColeccion === value.idColeccion)
        );
        setColecciones(unicosCol);

        let can = array.map((c) => parseFloat(c.inventario));
        let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
        setTotalTotal(TCan);

        let pr = array.map((c) => parseFloat(c.porRecibir));
        let TPR = pr.reduce((t, cantidad, index) => t + cantidad, 0);
        setTotalPR(TPR);

        let ped = array.map((c) => parseFloat(c.pedido));
        let TPed = ped.reduce((t, cantidad, index) => t + cantidad, 0);
        setTotalPed(TPed);

        // let tr = array.map((c) => parseFloat(c.traspaso));
        // let TTR = tr.reduce((t, cantidad, index) => t + cantidad, 0);
        // setTotalTR(TTR);

        let dis = array.map((c) => parseFloat(c.disponible));
        let TDis = dis.reduce((t, cantidad, index) => t + cantidad, 0);
        setTotalDis(TDis);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    let total_inventario = 0;
    let total_porRecibir = 0;
    let total_pedido = 0;
    let total_traspaso = 0;
    let total_disponible = 0;

    const data = comments
      .filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.porRecibir.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) 
        ) {
          total_inventario =
            parseFloat(total_inventario) + parseFloat(a.inventario); 
          total_porRecibir = parseFloat(total_porRecibir) + parseFloat(a.porRecibir);
          total_pedido = parseFloat(total_pedido) + parseFloat(a.pedido);
          total_traspaso = parseFloat(total_traspaso) + parseFloat(a.traspaso);
          total_disponible =
            parseFloat(total_disponible) + parseFloat(a.disponible);
          return [
            a.codigo,
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.porRecibir),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.traspaso),
            new Intl.NumberFormat("en-US").format(a.disponible),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [["Codigo", "Nombre", "Inventario", "Por Recibir", "Pedido", "Traspaso", "Disponible"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_porRecibir),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_traspaso),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Disponible.pdf`);
  }

  function excel() {
    const dataExcel = comments
      .filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.porRecibir.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) 
        ) {
          return {
            Codigo: a.codigo,
            Nombre: a.nombre,
            Inventario: a.inventario,
            PorRecibir: a.porRecibir,
            Pedido: a.pedido,
            Traspaso: a.traspaso,
            Disponible: a.disponible,
          };
        }
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDisponible";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDisponible",
        sheetFilter: ["Codigo", "Nombre", "Inventario", "PorRecibir", "Pedido", "Traspaso", "Disponible"],
        sheetHeader: ["Codigo", "Nombre", "Inventario", "PorRecibir", "Pedido", "Traspaso", "Disponible"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_inventario = 0;
    let total_porRecibir = 0;
    let total_pedido = 0;
    let total_traspaso = 0;
    let total_disponible = 0;

    const data = comments
      .filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.porRecibir.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) 
        ) {
          total_inventario = total_inventario + a.inventario;
          total_porRecibir = total_porRecibir + a.porRecibir;
          total_pedido = total_pedido + a.pedido;
          total_traspaso = total_traspaso + a.traspaso;
          total_disponible = total_disponible + a.disponible;
          return [
            a.codigo,
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.porRecibir),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.traspaso),
            new Intl.NumberFormat("en-US").format(a.disponible),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [["Codigo", "Nombre", "Inventario", "Por Recibir", "Pedido", "Traspaso", "Disponible"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_porRecibir),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_traspaso),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Disponible",
          email: mailTo,
          fileName: "ListadoDisponible.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Año", field: "ano", sortable: true },
    { name: "Inventario", field: "inventario", sortable: true },
    { name: "Por Recibir", field: "porRecibir", sortable: true },
    { name: "Pendiente Surtir", field: "pedido", sortable: true },
    // { name: "Pendiente Traspaso", field: "traspaso", sortable: true },
    { name: "Disponible", field: "disponible", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.porRecibir.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.ano.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      );
    }


    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }

    if (selectedAno) {
      computedComments = computedComments.filter((e) =>
        e.ano.includes(selectedAno)
      );
    }

    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "porRecibir" &&
      sorting.field != "pedido" &&
      sorting.field != "traspaso" &&
      sorting.field != "ano" &&
      sorting.field != "disponible"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
        sorting.field == "porRecibir" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "ano" ||
        sorting.field == "disponible")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
        sorting.field == "porRecibir" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "ano" ||
        sorting.field == "disponible")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, selectedArticulo, selectedColeccion, selectedAno]);

  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEditArticulo(idEdit);
    toggleFoto();
  }

  function jalaPedidos(id){
    setArticulosPedidos([])
    axios
    .get(`${URL_ARTICULOS_PEDIDO}Articulo/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allMovs = response.data
      setArticulosPedidos(allMovs);
    })
    .catch((err) => {
      console.log(err);
    })
  
      setIdEditArticulo(id);
      togglePedidos();
    }

    function jalaFechasEstimada(idArticulo, codigo, nombre) {
      setCodigoFecha(codigo);
      setNombreFecha(nombre);
      axios
        .get(`${URL_ARTICULOS_EMBARQUES}/PendRecibirArticulo/${idArticulo}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allfechasEstimada = response.data;
          setFechasEstimada(allfechasEstimada);
        })
        .catch((err) => {
          console.log(err);
        });
      toggleFechasEstimada();
    }

  let totalDisponible = 0;
  let totalPedido = 0;
  let totalTraspaso = 0;
  let totalInventario = 0;
  let totalPorRecibir = 0;

  let totalCantidad = 0
  let totalSurtido = 0
  let totalPendiente = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_disponible ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Disponible en Pantacos</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.coleccion > b.coleccion ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idColeccion}>{a.coleccion}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAno}
                  onChange={(e) => {
                    setSelectedAno(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {anos
                    .sort((a, b) => (a.ano > b.ano ? 1 : -1))
                    .map((a) => {
                      return <option value={a.ano}>{a.ano}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                totalInventario = totalInventario + a.inventario;
                totalPorRecibir = totalPorRecibir + a.porRecibir;
                totalPedido = totalPedido + a.pedido;
                totalTraspaso = totalTraspaso + a.traspaso;
                totalDisponible = totalDisponible + a.disponible;
                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.coleccion}</td>
                      <td>{a.ano}</td>

                      <td>
                        {new Intl.NumberFormat("en-US").format(a.inventario)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.porRecibir)}
                      </td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                      {/* <td>{new Intl.NumberFormat("en-US").format(a.traspaso)}</td> */}
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.disponible)}
                      </td>
                      <td>
                        <Button
                          color="info"
                          id="Editar"
                          size="sm"
                          onClick={(e) => jalaFoto(a.idArticulo)}
                        >
                          <i class="fas fa-camera"></i>
                        </Button>
                        <Button
                          color="info"
                          id="Editar"
                          size="sm"
                          onClick={(e) => jalaPedidos(a.idArticulo)}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={(e) =>
                            jalaFechasEstimada(
                              a.idArticulo,
                              a.codigo,
                              a.nombre
                            )
                          }
                        >
                          <i class="far fa-calendar-alt"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalInventario)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPorRecibir)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPedido)}
                </td>
                {/* <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTraspaso)}
                </td> */}
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalDisponible)}
                </td>
                <td></td>
                <td></td>
              </tr> <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL GENERAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotal)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPR)}
                </td> 
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPed)}
                </td>
                 {/* <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTR)}
                </td>  */}
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalDis)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Ficha Tecnica</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/giovanniGaliArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido una Ficha Tecnica....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalPedidos} toggle={togglePedidos}>
        <ModalHeader toggle={togglePedidos}>
          <h4>Analisis Pedidos</h4>
        </ModalHeader>
        <ModalBody>
        <Table size="sm" striped borderless className="table-responsive-xl">
                                    
            <tbody>
              <tr>
              <th>Pedido</th>
              <th>Cliente</th>
              <th>Almacen</th>
              <th>Activo</th>
              <th>Apartado</th>
              <th>Aut Surtir</th>
              <th>Cantidad</th>
              <th>Surtido</th>
              <th>Pend Surtir</th>
              </tr>
              {articulosPedidos.map((a) => {
                if(a.is_active == "Si"){
                totalCantidad = totalCantidad + parseFloat(a.cantidad);
                totalSurtido = totalSurtido + parseFloat(a.surtido);
                totalPendiente = totalPendiente + parseFloat(a.pendiente_surtir);
                  return (
                    <tr>
                      <td>{a.pedidos[0].idPedido}</td>
                      <td>{a.pedidos[0].clientes[0].nombre_comercial}</td>
                      <td>{a.pedidos[0].areas[0].name}</td>
                      <td>{a.is_active}</td>
                      <td>{a.autorizadoApartado}</td>
                      <td>{a.autorizadoSurtido}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                    </tr>
                  )}
              })}


              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTALES</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidad)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalSurtido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPendiente)}
                </td>


              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalFechasEstimada}
        toggle={toggleFechasEstimada}
      >
        <ModalHeader toggle={toggleFechasEstimada}>
          <h4>Fechas Estimada de Entrega</h4>
          <h4>
            Codigo: {codigoFecha}, Articulo: {nombreFecha}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Embarque</th>
              <th>Contenedor</th>
              <th>Cantidad Embarque</th>
              <th>Recibido</th>
              <th>Pendiente Recibir</th>
              <th>Fecha Estimada</th>
            </tr>
            <tbody>
              {fechasEstimada.map((a) => {
                return (
                  <tr>
                    <td align="center">{a.idEmbarques}</td>
                    <td align="center">{a.contenedor}</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US").format(a.cantidad)}
                    </td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US").format(a.recibido)}
                    </td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US").format(
                        a.pendiente_recibir
                      )}
                    </td>
                    <td align="center">{a.fechaEstimada}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoDisponiblePantacos;
