import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPendienteSurtir() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_MATRIZ = process.env.REACT_APP_AREAS_MATRIZ;
  const AREAS_BODEGA = process.env.REACT_APP_AREAS_BODEGA;
  const AREAS_PANTACO = process.env.REACT_APP_AREAS_PANTACO;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);
  const [totalDinero, setTotalDinero] = useState(0);

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");
  const [paqueteriasUnicos, setPaqueteriasUnicos] = useState([]);
  const [selectedPaqueteria, setSelectedPaqueteria] = useState([]);
  const [alertasCliente, setAlertasCliente] = useState("No");

  const [articulosPed, setArticulosPed] = useState([]);
  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);


  const [observacionesPedido, setObservacionesPedido] = useState("NA");

  const [colaboradoresNombre, setColaboradoresNombre] = useState("");
  const [area, setArea] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [noExt, setNoExt] = useState("");
  const [noInt, setNoInt] = useState("");
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [cp, setCp] = useState("");
  const [colonia, setColonia] = useState("");
  const [paqueteria, setPaqueteria] = useState("");
  const [telefono, setTelefono] = useState();

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [abrirSurtir, setAbrirSurtir] = useState(false);

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState("");
  const [comisionFlete, setComisionFlete] = useState(0);
  const [comisionTDC, setComisionTDC] = useState(0);

  const [idColaboradores, setIdColaboradores] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      articuloNombre:"",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      cajas: 0,
      piezasPorCaja: 0,
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      costoUnitario:0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    axios
      .get(`${URL_PEDIDOS}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.fechaModificado < b.fechaModificado ? 1 : -1))
          .map((a) => {
            if (user.areas == AREAS_GENERAL) {
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                colaboradores:
                  a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                codigo: a.clientes[0].codigo,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                status: a.status,
                autorizadoApartado: a.autorizadoApartado,
                autorizadoSurtido: a.autorizadoSurtido,
                idArea: a.areas[0]._id,
                area: a.areas[0].name,
                surtido: a.surtido,
                impuestos: a.impuestos,
                editado: a.editado,
                comisionFlete: a.comisionFlete,
                comisionTDC: a.comisionTDC,
                calle: a.clientes[0].calle,
                numero_ext: a.clientes[0].numero_ext,
                numero_int: a.clientes[0].numero_int,
                pais: a.clientes[0].pais,
                estado: a.clientes[0].estado,
                ciudad: a.clientes[0].ciudad,
                delegacion: a.clientes[0].delegacion,
                colonia: a.clientes[0].colonia,
                cp: a.clientes[0].cp,
                paqueteria: a.paqueterias[0].name,
                idPaqueteria: a.paqueterias[0]._id,
                RFC: a.clientes[0].RFC,
                fechaModificado: a.fechaModificado,
                telefono: a.clientes[0].telefono,
                descuento: a.descuento,
                alertas: a.clientes[0].alertas,
              };
            } else if (user.areas == AREAS_PANTACO) {
              if (a.areas[0]._id == AREAS_PANTACO) {
                return {
                  _id: a._id,
                  numero: a.idPedido,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.colaboradores[0].nombre +
                    " " +
                    a.colaboradores[0].apellido,
                  idColaboradores: a.colaboradores[0]._id,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  codigo: a.clientes[0].codigo,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idArea: a.areas[0]._id,
                  area: a.areas[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  comisionFlete: a.comisionFlete,
                  comisionTDC: a.comisionTDC,
                  calle: a.clientes[0].calle,
                  numero_ext: a.clientes[0].numero_ext,
                  numero_int: a.clientes[0].numero_int,
                  pais: a.clientes[0].pais,
                  estado: a.clientes[0].estado,
                  ciudad: a.clientes[0].ciudad,
                  delegacion: a.clientes[0].delegacion,
                  colonia: a.clientes[0].colonia,
                  cp: a.clientes[0].cp,
                  paqueteria: a.paqueterias[0].name,
                  idPaqueteria: a.paqueterias[0]._id,
                  RFC: a.clientes[0].RFC,
                  fechaModificado: a.fechaModificado,
                  telefono: a.clientes[0].telefono,
                  descuento: a.descuento,
                  alertas: a.clientes[0].alertas,
                };
              }
            } else if (user.areas == AREAS_MATRIZ) {
              if (a.areas[0]._id == AREAS_MATRIZ) {
                return {
                  _id: a._id,
                  numero: a.idPedido,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.colaboradores[0].nombre +
                    " " +
                    a.colaboradores[0].apellido,
                  idColaboradores: a.colaboradores[0]._id,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  codigo: a.clientes[0].codigo,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idArea: a.areas[0]._id,
                  area: a.areas[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  comisionFlete: a.comisionFlete,
                  comisionTDC: a.comisionTDC,
                  calle: a.clientes[0].calle,
                  numero_ext: a.clientes[0].numero_ext,
                  numero_int: a.clientes[0].numero_int,
                  pais: a.clientes[0].pais,
                  estado: a.clientes[0].estado,
                  ciudad: a.clientes[0].ciudad,
                  delegacion: a.clientes[0].delegacion,
                  colonia: a.clientes[0].colonia,
                  cp: a.clientes[0].cp,
                  paqueteria: a.paqueterias[0].name,
                  idPaqueteria: a.paqueterias[0]._id,
                  RFC: a.clientes[0].RFC,
                  fechaModificado: a.fechaModificado,
                  telefono: a.clientes[0].telefono,
                  descuento: a.descuento,
                  alertas: a.clientes[0].alertas,
                };
              }
            } else if (user.areas == AREAS_BODEGA) {
              if (a.areas[0]._id == AREAS_BODEGA) {
                return {
                  _id: a._id,
                  numero: a.idPedido,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.colaboradores[0].nombre +
                    " " +
                    a.colaboradores[0].apellido,
                  idColaboradores: a.colaboradores[0]._id,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  codigo: a.clientes[0].codigo,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idArea: a.areas[0]._id,
                  area: a.areas[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  comisionFlete: a.comisionFlete,
                  comisionTDC: a.comisionTDC,
                  calle: a.clientes[0].calle,
                  numero_ext: a.clientes[0].numero_ext,
                  numero_int: a.clientes[0].numero_int,
                  pais: a.clientes[0].pais,
                  estado: a.clientes[0].estado,
                  ciudad: a.clientes[0].ciudad,
                  delegacion: a.clientes[0].delegacion,
                  colonia: a.clientes[0].colonia,
                  cp: a.clientes[0].cp,
                  paqueteria: a.paqueterias[0].name,
                  idPaqueteria: a.paqueterias[0]._id,
                  RFC: a.clientes[0].RFC,
                  fechaModificado: a.fechaModificado,
                  telefono: a.clientes[0].telefono,
                  descuento: a.descuento,
                  alertas: a.clientes[0].alertas,
                };
              }
            } 
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setPedidos(allPedidos);
        setComments(data);

        let unicos = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.paqueteria === value.paqueteria)
        );
        setPaqueteriasUnicos(unicos);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}SurtirMATRIZPANTACO`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    codigo,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idArea,
    idPDFOC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    telefono
  ) {
    setSelectedArea(idArea);

    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;

        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              articulos: a.articulos[0].codigo,
              id: a.articulos[0]._id,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              pendiente_surtir: a.pendiente_surtir,
              cajas: parseFloat(a.pendiente_surtir) / parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              total: a.total
            };
          })
          .filter(function (el) {
            return el != null;
          });

          let dataFinal = Object.values(arrayTabla);

          let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
          let TK = totalKG.reduce((t, total, index) => t + total, 0);
  
          let importes = dataFinal.map((c) => parseFloat(c.total));
          let TG = importes.reduce((t, total, index) => t + total, 0);
  
          let TC = dataFinal.map((c) => parseFloat(c.cajas));
          let TTC = TC.reduce((t, total, index) => t + total, 0);
  
          let iva_tabla = (TG * impuestos) / 100;
  
          const data = dataFinal
          .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
          .map((a) => {
            // totalParcial = a.cantidad * a.precio;
            // subTotalTabla = subTotalTabla + totalParcial
            // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
            return [
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US").format(a.surtido),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.precio),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.total),
            ];
          });
          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });
          const doc = new jsPDF({compressPdf: true});
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 8, 5, 18, 5);
          var img2 = new Image();
          img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img2, "png", 10, 11, 15, 15);
  
          doc.setFontSize(12);
          doc.text(`Fecha:`, 35, 15);
          doc.text(`${fecha}`, 60, 15);
          doc.text(`No. Pedido:`, 35, 20);
          doc.text(`${numero}`, 60, 20);
  
          doc.text(`Ejecutivo:`, 120, 15);
          doc.text(`${colaboradores}`, 150, 15);
          doc.text(`Aut. Apartado:`, 120, 20);
  
          doc.setFontSize(8);
          doc.text(`Cliente:  ${clientes}`, 10, 32);
          doc.text(`RFC: ${RFC}`, 150, 32);
          doc.text(
            `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,
            10,
            42
          );
          doc.text(
            `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,
            10,
            47
          );
  
          doc.autoTable({
            head: [
              [
                "Articulo",
                "Cajas",
                "Cantidad",
                "Surtido",
                "Precio",
                "Total",
              ],
            ],
            body: dataPDFLimpia,
            styles: {
              fontSize: 8,
              // overflow: "linebreak",
              // columnWidth: "wrap",
              // // font: 'arial',
              // overflowColumns: "linebreak",
            },
            margin: { left: 5, right: 5 },
            startY: 50,
            foot: [
              [
                "",
                new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
                new Intl.NumberFormat("en-US").format(TK) + " Piezas",
                "",
                "Sub Total",
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(TG),
              ],
              [
                "",
                "",
                "",
                "",
                `Comision`,
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(iva_tabla),
              ],
              [
                "",
                "",
                "",
                "",
                "Comision TDC",
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(comisionTDC),
              ],
              [
                "",
                "",
                "",
                "",
                "Flete",
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(comisionFlete),
              ],
              [
                "",
                "",
                "",
                "",
                "Total",
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(total_general),
              ],
            ],
            showFoot: "lastPage",
          });
  
          doc.setFontSize(10);
          var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
          doc.text(10, doc.autoTable.previous.finalY + 10, lines);
  
          doc.save(`Pedido-${numero}.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
async  function excelOC(
    idArea,
    idPDFOC,
    clientes,
    ) {

      await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir/${idPDFOC}/${idArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data.AP;
        let allInventarios = response.data.AI;

        let allInventarios2 = allInventarios
          .map((a) => {
            return {
              idArticulo: a.articulos[0]._id,
              articulo: a.articulos[0].codigo,
              cantidadInventario: a.cantidad,
              almacen: a.areas[0].name,
              cantidadPedido: 0,
              surtido: 0,
              pendiente_surtir: 0,
              cajas: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataInv = Object.values(allInventarios2);

        let agrupado = dataInv
          // .sort((a, b) => (a.pack > b.pack ? 1 : -1))
          .reduce(function (groups, item) {
            const val = item["idArticulo"];
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              cajas: 0,
            };
            groups[val].articulo = item.articulo;
            groups[val].cantidadPedido = item.cantidadPedido;
            groups[val].surtido = item.surtido;
            groups[val].pendiente_surtir = item.pendiente_surtir;
            groups[val].cajas = item.cajas;
            groups[val].piezasPorCaja = item.piezasPorCaja;
            return groups;
          }, []);

        let dataAgrupado = Object.values(agrupado);

        let allArticulosPedido2 = allArticulosPedido
          .map((a) => {
            return {
              articulo: a.articulos[0].codigo,
              idArticulo: a.articulos[0]._id,
              cantidadPedido: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
              cajas:
                parseFloat(a.pendiente_surtir) /
                parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataPedidos = Object.values(allArticulosPedido2);

        // dataPedidos.map((a) =>
        //   dataAgrupado.map((b) => {
        //     if (a.idArticulo == b.idArticulo) {
        //       a.pack = b.pack;
        //     }
        //     return a;
        //   })
        // );


    const dataExcel = dataPedidos.map((a) => {
        return {
          Cliente: clientes,
          Articulo: a.articulo,
          PzsCaja: a.piezasPorCaja,
          Cajas: a.cajas,
          PendSurtir: a.pendiente_surtir,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "DesglocePedido";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "DesglocePedido",
        sheetFilter: [
        "Cliente",
        "Articulo", 
        "PzsCaja", 
        "Cajas", 
        "PendSurtir", 
        ],
        sheetHeader: [
        "Cliente",
        "Articulo", 
        "PzsCaja", 
        "Cajas", 
        "PendSurtir", 
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Ultima Mod", field: "fechaModificacion", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Paqueteria", field: "paqueteria", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "PDF", field: "pdf", sortable: false },
    { name: "Surtir", field: "surtir", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.paqueteria.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedPaqueteria) {
      computedComments = computedComments.filter((e) =>
        e.paqueteria.includes(selectedPaqueteria)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedPaqueteria,
    selectedColaborador,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let iva = 0;
    let TT = 0;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    if (impuestos != 0) {
      iva = (impuestos * ST) / 100;
    } else {
      iva = 0;
    }

    let temp1 = (ST * descuento) / 100;

    TT = (impuestos * ST) / 100 + ST + comisionFlete + comisionTDC - temp1


    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            pedidos: selectedPedido,
            observaciones,
            user: user.id,
            clientes: cliente,
            areas: selectedArea,
            total_general: parseFloat(TT).toFixed(2),
            total_cantidad: total,
            saldo: parseFloat(TT).toFixed(2),
            subTotal: parseFloat(ST).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos,
            is_active:"Si",
            descuento,
            colaboradores: idColaboradores,
            comisionPagada:"No"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          if(alertasCliente == "Si"){
          PDFOCSurtido(
                data.data.idSurtido,
                data.data.updatedAt,
              );
          }
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    fecha,
                    surtidos: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    pedidos: selectedPedido,
                    articulosPedido: a.idArtPed,
                    costoUnitario: a.costoUnitario
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_pedido} ${selectedArea} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_pedido} ${selectedArea} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  async function PDFOCSurtido(
    numero, 
    fechaHora
    ) {

    let fechaHora2 = moment(fechaHora).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(fechaHora).utcOffset('-0600').format("HH:mm:ss")

    let iva = 0;
    let TT = 0;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    if (impuestos != 0) {
      iva = (impuestos * ST) / 100;
    } else {
      iva = 0;
    }

    let temp1 = (ST * descuento) / 100;

    TT = (impuestos * ST) / 100 + ST + comisionFlete + comisionTDC - temp1


    const dataIF = inputFields.map((a) => {
      if(a.cantidad > 0){
            return {
              articulos: a.articulos,
              articuloNombre: a.articuloNombre,
              cantidad: a.cantidad,
              idArticulo: a.idArtPed,
              precio: a.precio,
              cajas: a.cantidad / a.piezasPorCaja,
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(dataIF);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].articuloNombre = item.articuloNombre;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articuloNombre,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 15, 15);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`Nota:`, 35, 20);
        doc.text(`${numero}`, 60, 20);
        doc.text(`Pedido:`, 35, 25);
        doc.text(`${numero_pedido}`, 60, 25);

        doc.text(`Ejecutivo:`, 120, 15);
        doc.text(`${colaboradoresNombre}`, 150, 15);
        doc.text(`Almacen:`, 120, 20);
        doc.text(`${area}`, 150, 20);

        doc.setFontSize(8);
        doc.text(`Creado por: ${user.nombre} ${user.apellido}`, 120, 32);
        doc.text(`Fecha creado: ${fechaHora2}`, 120, 37);

        doc.text(`Cliente: ${clientesCodigo} - ${clientesName}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 10, 37);
        doc.text(`Direccion de Envio: ${calle} #${noExt} Int. ${noInt}`, 10, 47);
        doc.text(`Telefono: ${clientesName}`, 10, 52);
        doc.text(`Edo: ${estado}, Ciudad: ${ciudad}`, 10, 57);
        doc.text(` Del: ${delegacion}, Col: ${colonia}, CP: ${cp}`, 10, 62);

        doc.setFontSize(12);
        doc.text(`Paqueteria: ${paqueteria}`, 120, 50);
        doc.text(`Almacen: ${area}`, 120, 57);

        doc.autoTable({
          head: [["Articulo", "Cajas", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 12,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 70,
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(ST.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(TT.toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });
        var lines = doc.splitTextToSize(`Observaciones ${observacionesPedido}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Nota-${numero}.pdf`);

        var att = doc.output("arraybuffer");

        let params = new URLSearchParams({
          number: telefono,
          message:`Hola ${clientesName},  Aquí te enviamos la nota de tu compra, tu pedido ya está siendo procesado tu guía de embarque te la enviara tu ejecutivo en cuanto haya salido!
          Muchas gracias!
          Bendiciones!`,
          fileName:`Nota-${numero}.pdf`,
          mimetype: "application/pdf",
        })
        axios
        .post(
          `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
          att,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
              "Content-Type": "application/octet-stream",
            },
          }
        )
        //   }
        // });
        // });
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        articuloNombre:"",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (
          (i.cantInv >= event.target.value &&
            i.pendSurtir >= event.target.value) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (
          (i.cantInv >= cantTemp && i.pendSurtir >= cantTemp) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          i.total =
            parseFloat(event.target.value) *
            parseFloat(i.piezasPorCaja) *
            parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inventarios.filter((e) => e.idArticulo == event.target.value).length == 1
    ) {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticuloUnico(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    } else {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    }
  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
        i.cantInv = cantInv;
        i.idInv = idInv;
        i.costoUnitario = costoUnitario
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  // function BuscaPack(id, idInventario) {
  //   if (inputFields.filter((e) => e.idInv == idInventario).length > 0) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "El modelo en esa Pack ya fue capturado!",
  //       showConfirmButton: false,
  //     });
  //     const newInputFields = inputFields.map((i) => {
  //       if (id === i.id) {
  //         i.cantInv = 0;
  //         i.idInv = "";
  //       }
  //       return i;
  //     });
  //     setInputFields(newInputFields);
  //   } else {
  //     inventarios.map((a) => {
  //       if (a._id == idInventario) {
  //         let cantInv = parseFloat(a.cantidad);
  //         let idInv = a._id;
  //         let costoUnitario = a.costoUnitario
  //         handleChangeInputArticuloPack(id, cantInv, idInv, costoUnitario);
  //       }
  //     });
  //   }
  // }

  // const handleChangeInputArticuloPack = (id, cantInv, idInv, costoUnitario) => {
  //   const newInputFields = inputFields.map((i) => {
  //     if (id === i.id) {
  //       i.cantInv = parseFloat(cantInv);
  //       i.idInv = idInv;
  //       i.costoUnitario = costoUnitario
  //     }
  //     return i;
  //   });
  //   setInputFields(newInputFields);
  //   ActualizaTotales();
  // };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    let cantCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TTC);

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);
    setTotalDinero(ST)
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        let artPed = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.idArtPed;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let precio = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.precio;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              artPed,
              precio
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu pedido",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  async function seleccionarPedido(
    id,
    numero,
    idClientes,
    clientes,
    codigo,
    idArea,
    impuestos,
    comisionFlete,
    comisionTDC,
    descuento,
    idColaboradores,
    colaboradores,
    area,
    RFC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    paqueteria,
    observaciones,
    telefono,
    alertas
  ) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setCliente(idClientes);
    setSelectedArea(idArea);
    setImpuestos(impuestos);
    setComisionFlete(comisionFlete);
    setComisionTDC(comisionTDC);
    setClientesName(clientes);
    setClientesCodigo(codigo);
    setDescuento(descuento)
    setIdColaboradores(idColaboradores)
    setColaboradoresNombre(colaboradores)
    setArea(area)
    setRFC(RFC)
    setCalle(calle)
    setNoExt(numero_ext)
    setNoInt(numero_int)
    setPais(pais)
    setEstado(estado)
    setCiudad(ciudad)
    setDelegacion(delegacion)
    setCp(cp)
    setColonia(colonia)
    setPaqueteria(paqueteria)
    setObservacionesPedido(observaciones)
    setTelefono(telefono)
    setAlertasCliente(alertas)

    if(idArea == AREAS_MATRIZ || idArea == AREAS_BODEGA){
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir3/${id}/${idArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPed = res.data;
        setArticulosPed(allArticulosPed);

        let arrayInputFields = allArticulosPed
          .map((a) => {
            if(a.cantInv > a.pendSurtir){
            return {
              id: uuidv4(),
              articulos: a.articulos,
              cantidad: a.pendSurtir,
              cantInv: a.cantInv,
              idInv: a.idInv,
              idArtPed: a.idArtPed,
              cajas: a.pendSurtir / a.piezasPorCaja,
              piezasPorCaja: a.piezasPorCaja,
              precio: a.precio,
              total: a.precio * a.pendSurtir,
              pendSurtir: a.pendSurtir,
              articuloNombre: a.articuloNombre,
              cantPed: a.cantPed,
              costoUnitario: a.costoUnitario
            };
            }else{
              return {
                id: uuidv4(),
                articulos: a.articulos,
                cantidad: a.cantInv,
                cantInv: a.cantInv,
                idInv: a.idInv,
                idArtPed: a.idArtPed,
                cajas: a.pendSurtir / a.piezasPorCaja,
                piezasPorCaja: a.piezasPorCaja,
                precio: a.precio,
                total: a.precio * a.cantInv,
                pendSurtir: a.pendSurtir,
                articuloNombre: a.articuloNombre,
                cantPed: a.cantPed,
                costoUnitario: a.costoUnitario
              };
            }
          })
          .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);


        let cant = dataIF.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotal(TC);
    
        let cantCajas = dataIF.map((c) => parseFloat(c.cajas));
        let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
        setTotalCajas(TTC);

        let STotal = dataIF.map((c) => parseFloat(c.total));
        let ST = STotal.reduce((t, total, index) => t + total, 0);
        setTotalDinero(ST)

        let result = allArticulosPed.map((a) => a.articulos);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      })
    }else{
      
    axios
    .get(`${URL_ARTICULOS_PEDIDO}/surtir3PANTACO/${id}/${idArea}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosPed = res.data;
      setArticulosPed(allArticulosPed);

      let arrayInputFields = allArticulosPed
        .map((a) => {
          if(a.cantInv > a.pendSurtir){
          return {
            id: uuidv4(),
            articulos: a.articulos,
            cantidad: a.pendSurtir,
            cantInv: a.cantInv,
            idInv: a.idInv,
            idArtPed: a.idArtPed,
            cajas: a.pendSurtir / a.piezasPorCaja,
            piezasPorCaja: a.piezasPorCaja,
            precio: a.precio,
            total: a.precio * a.pendSurtir,
            pendSurtir: a.pendSurtir,
            articuloNombre: a.articuloNombre,
            cantPed: a.cantPed,
            costoUnitario: a.costoUnitario
          };
          }else{
            return {
              id: uuidv4(),
              articulos: a.articulos,
              cantidad: a.cantInv,
              cantInv: a.cantInv,
              idInv: a.idInv,
              idArtPed: a.idArtPed,
              cajas: a.pendSurtir / a.piezasPorCaja,
              piezasPorCaja: a.piezasPorCaja,
              precio: a.precio,
              total: a.precio * a.cantInv,
              pendSurtir: a.pendSurtir,
              articuloNombre: a.articuloNombre,
              cantPed: a.cantPed,
              costoUnitario: a.costoUnitario
            };
          }
        })
        .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

      let dataIF = Object.values(arrayInputFields);

      setInputFields(dataIF);

      let cant = dataIF.map((c) => parseFloat(c.cantidad));
      let TC = cant.reduce((t, total, index) => t + total, 0);
      setTotal(TC);
  
      let cantCajas = dataIF.map((c) => parseFloat(c.cajas));
      let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
      setTotalCajas(TTC);

      let STotal = dataIF.map((c) => parseFloat(c.total));
      let ST = STotal.reduce((t, total, index) => t + total, 0);
      setTotalDinero(ST)

      let result = allArticulosPed.map((a) => a.articulos);
      setIdsArticulos(result);
    })
    .catch((err) => {
      console.log(err);
    });
    }

    setAbrirSurtir(true);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos_create ? (
        <div className="card col-12">
          {!abrirSurtir ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pendiente Surtir</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pendientes de Surtir</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Clientes</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Vendedor</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={3}>
                <Label>Paqueteria</Label>
                    <Input
                      bsSize="sm"
                      name="paqueterias"
                      type="select"
                      value={selectedPaqueteria}
                      required
                      onChange={(e) => {
                        setSelectedPaqueteria(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {paqueteriasUnicos
                        .sort((a, b) => (a.paqueteria > b.paqueteria ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.paqueteria}>{a.paqueteria}</option>
                          );
                        })}
                    </Input>
                  </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if (a.editado == "Si") {
                      return (
                        <tr style={{ backgroundColor: "orange" }}>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.paqueteria}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.area}</td>
                          <td>
                            {user.surtidos_create ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.colaboradores,
                                      a.idClientes,
                                      a.clientes,
                                      a.codigo,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idArea,
                                      a._id,
                                      a.calle,
                                      a.numero_ext,
                                      a.numero_int,
                                      a.pais,
                                      a.estado,
                                      a.ciudad,
                                      a.delegacion,
                                      a.colonia,
                                      a.cp,
                                      a.RFC,
                                      a.paqueteria,
                                      a.area,
                                      a.telefono
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(
                                      a.idArea,
                                      a._id,
                                      a.clientes,
                                    )
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>

                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.descuento,
                                  a.idColaboradores,
                                  a.colaboradores,
                                  a.area,
                                  a.RFC,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.paqueteria,
                                  a.observaciones,
                                  a.telefono,
                                  a.alertas
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                            {"  "}Modificado
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.paqueteria}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.area}</td>
                          <td>
                            {user.surtidos_create ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.colaboradores,
                                      a.idClientes,
                                      a.clientes,
                                      a.codigo,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idArea,
                                      a._id,
                                      a.calle,
                                      a.numero_ext,
                                      a.numero_int,
                                      a.pais,
                                      a.estado,
                                      a.ciudad,
                                      a.delegacion,
                                      a.colonia,
                                      a.cp,
                                      a.RFC,
                                      a.paqueteria,
                                      a.area,
                                      a.telefono
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(
                                      a.idArea,
                                      a._id,
                                      a.clientes,
                                    )
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>

                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.descuento,
                                  a.idColaboradores,
                                  a.colaboradores,
                                  a.area,
                                  a.RFC,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.paqueteria,
                                  a.observaciones,
                                  a.telefono,
                                  a.alertas
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Pedido {numero_pedido}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Descuento %</Label>
                    <Input
                      type="number"
                      value={descuento}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>
                  <Col md={1}>
                    <Label className="mr-sm-2">Codigo QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Pedido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>
                  {/* {selectedArea == AREAS_MATRIZ || selectedArea == AREAS_BODEGA ? (
                    <Col md={1}>
                      <Label className="mr-sm-2">Pack</Label>
                    </Col>
                  ) : undefined} */}

                  <Col md={1}>
                    <Label className="mr-sm-2">Inventario</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="string"
                          value={inputField.articulos}
                          onChange={(event) => {
                            BuscaCodigo(inputField.id, event);
                          }}
                        ></Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticulo(inputField.id, event);
                          }}
                        >
                          <option value="">Selecciona un Articulo</option>
                          {articulosPed
                            .sort((a, b) =>
                              a.articuloNombre > b.articuloNombre ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos}>
                                  {a.articuloNombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantPed"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Ped"
                          value={inputField.cantPed}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>

                      {/* {selectedArea == AREAS_MATRIZ || selectedArea == AREAS_BODEGA ? (
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="pack"
                            type="select"
                            value={inputField.idInv}
                            onChange={(event) => {
                              BuscaPack(inputField.id, event.target.value);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {inventarios
                              .sort((a, b) =>
                                a.pack > b.pack ? 1 : -1
                              )
                              .map((a) => {
                                if (a.idArticulo == inputField.articulos && (a.idAreaSalida == selectedArea)) {
                                  return (
                                    <option value={a._id}>{a.pack}</option>
                                  );
                                }
                              })}
                          </Input>
                        </Col>
                      ) : undefined} */}

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantInv"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cajas"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cajas"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCantidadCajas(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezasPorCaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Piezas por Caja"
                          value={inputField.piezasPorCaja}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="total"
                          type="number"
                          min="0"
                          step="any"
                          value={inputField.total}
                          disabled
                        />
                      </Col>

                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {(totalCajas).toFixed(2)} Cajas / {total} Pzas. / ${(totalDinero).toFixed(2)}
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton && total > 0 ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteSurtir;
