import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function EmbarquesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_GASTOS_EMBARQUES = process.env.REACT_APP_URL_GASTOS_EMBARQUES;
  const URL_CONCEPTOS_GASTOS_EMBARQUES =
    process.env.REACT_APP_URL_CONCEPTOS_GASTOS_EMBARQUES;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fechaEmbarque, setFechaEmbarque] = useState(hoy);
  const [fechaEstimada, setFechaEstimada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);

  const [articulosOC, setArticulosOC] = useState([]);
  const [OC, setOC] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [contenedor, setContenedor] = useState("");
  const [totalCaptura, setTotalCaptura] = useState(1);
  const [proveedores, setProveedores] = useState([]);
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [agrupado, setAgrupado] = useState([]);

  const [conceptosGastos, setConceptosGastos] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      cantidadOC: 0,
      costo: 0,
      costoTotal: 0,
      proveedorOrden: "",
      OC:""
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS_OC, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

          axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOC = res.data;
        setOC(allOC);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CONCEPTOS_GASTOS_EMBARQUES}Fijos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveEmbarqueNuevo = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará el Embarque!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
    if (
      result.isConfirmed &&
      fechaEmbarque != "" &&
      fechaEstimada != "" &&
      contenedor != "" &&
      articulosOC != "" &&
      observaciones != ""
    ) {
      setValidaBoton(false);
      toggleProgreso();
     
      let totalArticulos =
        inputFields.length + inputFields.length + conceptosGastos.length;
      let TCosto = inputFields.map((c) => parseFloat(c.costoTotal));
      let TTC = TCosto.reduce((t, total, index) => t + total, 0);
      try {
        await axios
          .post(
            URL_EMBARQUES,
            {
              fechaEmbarque,
              fechaEstimada,
              observaciones,
              contenedor,
              total_cantidad,
              recibido: "No",
              total_costo: parseFloat(TTC).toFixed(2),
              saldo: parseFloat(TTC).toFixed(2),
              creado: user.id,
              total_gasto: 0,
              status: "Abierto",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            agrupado.map((b) => {
              axios
                .post(
                  `${URL_NOTAS_CXP}`,
                  {
                    // proveedores: "647a1dc3b5b71896bef71f98",
                    proveedores: b.proveedorOrden,
                    embarques: data.data._id,
                    total_cantidad: b.cantidad,
                    total_costo: parseFloat(b.costoTotal).toFixed(2),
                    saldo: parseFloat(b.costoTotal).toFixed(2),
                    calidad:"No",
                    fecha:fechaEmbarque
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data2) => {
                  inputFields.map((a) => {
                    if (
                      a.articulos != "" &&
                      a.cantidad != 0 &&
                      a.proveedorOrden == b.proveedorOrden
                    ) {
                      axios
                        .post(
                          `${URL_ARTICULOS_EMBARQUES}`,
                          {
                            articulosOC: a.articulosOrden,
                            articulos: a.articulos,
                            pack: "NA",
                            cantidad: a.cantidad,
                            recibido: 0,
                            pendiente_recibir: a.cantidad,
                            embarques: data.data._id,
                            notasCxP: data2.data._id,
                            costo: a.costo,
                            total_costo: a.costoTotal,
                            // proveedores: "647a1dc3b5b71896bef71f98",
                            proveedores: b.proveedorOrden,
                            pesoCaja: 0,
                            faltante: 0,
                            danado: 0,
                            impuestos: 0,
                            revisarFaltante: 10,
                            revisarDanado: 10,
                            pedirFaltante: 0,
                            pedirDanado: 10,
                            faltanteTotal: 0,
                            danadoTotal: 0,
                            descuentoFaltante: 0,
                            descuentoDanado: 0,
                            descuentoTotal: 0,
                            familias: "NA",
                            sup: "NA",
                            observaciones:"NA",
                            status: "Abierto",
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          totalArticulos = totalArticulos - 1;
                          if (totalArticulos == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Embarque",
                                  detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  "success"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              })
                              .catch((error) => {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!",
                                  footer: `${error.response.data}`,
                                });
                                console.log(error);
                                setValidaBoton(true);
                              });
                          }
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    } else {
                      totalArticulos = totalArticulos - 1;
                      if (totalArticulos == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Embarque",
                              detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              "success"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      }
                    }
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });

              // Acaba el primer map
            });

            inputFields.map((w) => {
              axios
                .patch(
                  `${URL_ARTICULOS_OC}editEmbarcado/${w.articulosOrden}`,
                  {
                    embarcado: w.cantidad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Embarque",
                          detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });

            // Acaba el segundo map

            conceptosGastos.map((w) => {
              axios
                .post(
                  URL_GASTOS_EMBARQUES,
                  {
                    conceptosGastosEmbarques: w._id,
                    estimado: w.estimado,
                    real: w.estimado,
                    embarques: data.data._id,
                    aplicado:"No"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Embarque",
                          detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });
          })
          // Catch primer then
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data.message}`,
            });
            console.log(error);
            setValidaBoton(true);
            setModalProgreso(false)
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
      setValidaBoton(true);
    }
  })
  };

  
  const handleAddFields = () => {
    let TC = totalCaptura + 1;
    const ultimo = inputFields[inputFields.length - 1];

    setTotalCaptura(TC);
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOrden: "",
        articulos: "",
        cantidad: 0,
        piezasPorCaja: 0,
        cajas: 0,
        cantidadOC: 0,
        costo: 0,
        costoTotal: 0,
        proveedorOrden: "",
        OC:""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaProveedor(id, event) {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.proveedorOrden = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  }

   function BuscaOC(id, event) {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.OC = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  }

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let articulos = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          handleChangeInputArticulo(id, articulos, piezasPorCaja);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, articulos, piezasPorCaja) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = articulos;
        i.piezasPorCaja = piezasPorCaja;
        i.cantidadOC = 0;
        i.articulosOrden = "";
        // i.cantidad= 0
        i.cajas = 0;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function BuscaOrden(id, event) {
    if( inputFields.filter((e) => e.articulosOrden == event.target.value).length > 0){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosOrden = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else{
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOrden = a._id;
        let cantidadOC = a.cantidad;
        let costo = a.precio
        let articulo = a.articulos[0]._id
        handleChangeInputOC(id, articulosOrden, cantidadOC, costo, articulo);
      }
    });}
  }
  const handleChangeInputOC = (id, articulosOrden, cantidadOC, costo, articulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden;
        i.cantidadOC = cantidadOC;
        i.costo = costo;
        i.costoTotal = parseFloat(costo) * parseFloat(i.cantidad);
        i.articulos = articulo
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["proveedorOrden"];
      groups[val] = groups[val] || {
        proveedorOrden: item.proveedorOrden,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      return groups;
    }, []);
    let temp = Object.values(agrupado)
 setAgrupado(temp)
  }

  // Verificar cantidad

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i[event.target.name] = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costoTotal =
          parseFloat(event.target.value) *
          parseFloat(i.piezasPorCaja) *
          parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  // function importarArchivo(event){
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   let hojas = [];
   
  //   if (name === "file") {
  
  //     let reader = new FileReader();
  //       reader.readAsArrayBuffer(target.files[0]);
  //       reader.onloadend = (e) => {
  //         var data = new Uint8Array(e.target.result);
  //         var workbook = XLSX.read(data, { type: "array" });
  
  //         workbook.SheetNames.forEach(function (sheetName) {
  //           // Here is your object
  //           var XL_row_object = XLSX.utils.sheet_to_json(
  //             workbook.Sheets[sheetName]
  //           );
  //           hojas.push({
  //             data: XL_row_object,
  //             sheetName,
  //           });
  //         });
  
  //         let A1 = hojas[0].data
  
  //         let TC = totalCaptura
  //         A1.map((a)=>{
  //            TC = TC + 1
  //           setTotalCaptura(TC)
  //         inputFields.push({
  //           id: uuidv4(),
  //           articulosOrden: a.articulosOrden,
  //           articulos: a.articulos,
  //           cantidad: a.cantidad,
  //           piezasPorCaja: 0,
  //           cajas: 0,
  //           cantidadOC: a.cantidad,
  //           costo: a.costo,
  //           costoTotal: a.costoTotal,
  //           proveedorOrden: "647a1dc3b5b71896bef71f98",
  //           OC: "648765487c7b450bf6d2cadf"
  //         })
  //         })
  
  //         ActualizaTotales()
  //       }
  //   }
  // }
  // console.log("hola", inputFields)

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Embarque</h3>
          <Form onSubmit={saveEmbarqueNuevo}>
            <Row>
              <Col md={2}>
                <Label>Fecha Embarque</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEmbarque}
                  required
                  onChange={(e) => {
                    setFechaEmbarque(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label># Contenedor</Label>
                <Input
                  type="text"
                  placeholder="contenedor"
                  value={contenedor}
                  required
                  onChange={(e) => {
                    setContenedor(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Estimada de Arribo</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEstimada}
                  required
                  onChange={(e) => {
                    setFechaEstimada(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>
            {/* <Row>

            <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) =>
                        importarArchivo(event)
                      }
                    />
                    </Col>
            </Row> */}

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">OC</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad OC</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">FOB</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Pzas por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="proveedor"
                      type="select"
                      value={inputField.proveedorOrden}
                      required
                      onChange={(event) => {
                        BuscaProveedor(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Proveedor</option>
                      {proveedores
                      .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="OC"
                      type="select"
                      value={inputField.OC}
                      required
                      onChange={(event) => {
                        BuscaOC(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona </option>
                      {OC
                      .sort((a, b) => (a.idOrdenCompra > b.idOrdenCompra ? 1 : -1))
                      .map((a) => {
                        if(a.autorizado == "Si" &&
                        a.status == "Abierto" &&
                        a.proveedores[0]._id ==
                          inputField.proveedorOrden){
                        return (
                          <option value={a._id}>
                            {a.idOrdenCompra}
                          </option>
                        );}
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="orden"
                      type="select"
                      value={inputField.articulosOrden}
                      required
                      onChange={(event) => {
                        BuscaOrden(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona una OC</option>
                      {articulosOC
                      .sort((a, b) => (a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1))
                      .map((a) => {
                        if (
                          a.ordenesCompra[0]._id == inputField.OC
                        )
                          return (
                            <option value={a._id}>
                              {a.articulos[0].codigo} Cant{" "} {a.cantidad} 
                            </option>
                          );
                      })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadOC"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantidadOC}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="FOB"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.costo}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCantidadCajas(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="string"
                      value={inputField.piezasPorCaja}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoEmbarques"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EmbarquesCreate;
