import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, Label, Input, Col, Row } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function ColaboradoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PUESTOS = process.env.REACT_APP_URL_PUESTOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SIGNUP = process.env.REACT_APP_URL_USERS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [text, setText] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [direccion, setDireccion] = useState("");
  const [fecha_ingreso, setFechaIngreso] = useState([]);
  const [fecha_nacimiento, setFechaNacimiento] = useState([]);
  const [telefono, setTelefono] = useState("");
  const [frecuencia, setFrecuencia] = useState("");
  const [contacto_emergencia, setContEmer] = useState("");
  const [telefono_emergencia, setTelEmer] = useState("");
  const [sueldo_actual, setSueldoActual] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [email, setEmail] = useState("");
  const [puestos, setPuestos] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState("");
  const [banco, setBanco] = useState("");
  const [clabe, setClabe] = useState("");
  const [cobrador, setCobrador] = useState("No");
  const [vendedor, setVendedor] = useState("No");
  const [acceso_sistema, setAccesoSistema] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(
    process.env.REACT_APP_AREAS_GENERAL
  );
  const [password, setPassword] = useState(
    Math.random().toString(36).substring(7)
  );
  const [menu_cobradores, setMenuCobradores] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_PUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPuestos = res.data;
        setPuestos(allPuestos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveColaborador = async (event) => {
    if (cobrador == "Si") {
      setMenuCobradores(true);
    } else {
      setMenuCobradores(false);
    }
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_COLABORADORES,
          {
            nombre,
            apellido,
            identificacion: "NA",
            email,
            direccion: "NA",
            fecha_ingreso,
            fecha_nacimiento:"2020-01-01",
            frecuencia:"Semanal",
            telefono,
            contacto_emergencia:"NA",
            telefono_emergencia:0,
            sueldo_actual:0,
            observaciones:"NA",
            puestos: selectedPuesto,
            banco:"NA",
            clabe:0,
            cobrador,
            ultimo_aumento: fecha_ingreso,
            sueldo_anterior: 0,
            areas: selectedArea,
            acceso_sistema,
            vendedor,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
            .get(`${URL_COLABORADORES}/email/${email}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              const idPost = response.data[0]._id;
              axios
                .post(
                  URL_SIGNUP,
                  {
                    nombre,
                    apellido,
                    email,
                    password,
                    colaboradores: idPost,
                    areas: selectedArea,
                    is_active: acceso_sistema,
                    menu_cobradores,
                    vendedor,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Empleado",
                      detalle: `Nombre: ${nombre} / Apellido: ${apellido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.empleados_create ? (
        <div className="card container">
          <h3 align="center">Dar de alta un Empleado</h3>
          <Form onSubmit={saveColaborador}>
            <Row>
              <Col md={4}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Apellido</Label>
                <Input
                  type="text"
                  placeholder="Apellido"
                  value={apellido}
                  required
                  onChange={(e) => {
                    setApellido(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Email</Label>
                <Input
                  type="text"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Puesto</Label>
                <Input
                  type="select"
                  value={selectedPuesto}
                  required
                  onChange={(e) => {
                    setSelectedPuesto(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Puesto</option>
                  {puestos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={4}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Numero de Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Fecha de Ingreso</Label>
                <Input
                  type="date"
                  value={fecha_ingreso}
                  required
                  onChange={(e) => {
                    setFechaIngreso(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Area</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option>Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Acceso al Sistema
                </Label>
                <Input
                  type="select"
                  value={acceso_sistema}
                  required
                  onChange={(e) => {
                    setAccesoSistema(e.target.value);
                  }}
                >
                  <option value="0">Acceso al Sistema</option>
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Password</Label>
                <Input type="text" placeholder={password} disabled />
              </Col> 

              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Vendedor
                </Label>
                <Input
                  type="select"
                  value={vendedor}
                  required
                  onChange={(e) => {
                    setVendedor(e.target.value);
                  }}
                >
                  <option value="0">Vendedor</option>
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Input>
              </Col>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/Empleados"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                  window.location.href = "/Empleados";
                }}
              />
            </div>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default ColaboradoresCreate;
