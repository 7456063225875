import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";

import Header from "../../layout/Header/Header";

function BotonesCXP() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.configAdmin ?(
      <div className="container">
        <br />
        <br />
          <br />
          <Row>
          <Col md={4} align="center">
          {user.edoCtaProveedores ?(
            <Button href="/ListadoComprasCXP" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Compras
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Compras
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.pagoProveedores ?(
            <Button href="/ListadoAbonosProveedores" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>

        
          <Col md={4} align="center">
          {user.edoCtaProveedores ?(
            <Button href="/EstadoCuentaCXP" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>


          </Row>

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCXP;
